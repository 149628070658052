<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增') + '用户'" :width="800" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 250px' }" layout="inline">
        <a-row type="flex" justify="space-between" style="margin-left:16px">
          <a-form-model-item label="租户名称" prop="name">
              <a-input v-model="form.name" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="所属行业" prop="industryCode">
          <a-select v-model="form.industryCode" style="width:250px">
            <a-select-option v-for="(item, index) in industrys" :key="index" :value="item.code">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-row>
        <a-row type="flex" justify="space-between" style="margin-left:16px">
          <a-form-model-item label="租户编号" prop="code">
              <a-input v-model="form.code"  placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="contacts">
              <a-input v-model="form.contacts" placeholder="请输入"/>
          </a-form-model-item>
        </a-row>
        <a-row type="flex" justify="space-between" style="margin-left:16px">
         <a-form-model-item label="租户账号" prop="tenantUserName">
            <a-input v-model="form.tenantUserName" placeholder="请输入"/>
        </a-form-model-item>
         <a-form-model-item label="联系电话" prop="tel">
            <a-input v-model="form.tel" placeholder="请输入"/>
        </a-form-model-item>
        </a-row>
        <a-row type="flex" justify="space-between" style="margin-left:16px">
         <a-form-model-item label="默认密码" prop="initPassword">
            <a-input v-model="form.initPassword" placeholder="请输入"/>
        </a-form-model-item>
         <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="form.email"/>
        </a-form-model-item>
        </a-row>
        <a-row type="flex" justify="space-between" style="margin-left:16px">
          <a-form-model-item label="有效期" prop="gmtStartTime">
            <a-range-picker v-model="time" @change="onChange" style="width: 250px" />
          </a-form-model-item>
          <a-form-model-item label="传真">
            <a-input v-model="form.fax"/>
          </a-form-model-item>
        </a-row>
        <!-- <a-row type="flex" >
          <a-form-model-item label="联系地址">
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.address" placeholder="详细地址"/>
          </a-form-model-item>
        </a-row> -->
        <a-row type="flex" justify="space-between" style="margin-left:16px">
          <a-form-model-item label="备注">
              <a-input v-model="form.memo"  type = "textarea" placeholder="请输入你的介绍描述" :rows="4"/>
          </a-form-model-item>
        </a-row>
      </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  data () {
    const validEmail = (rule, value, callback) => {
      if (value && !(/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(value))) {
        return callback(new Error('请输入正确的邮箱'))
      }
      callback()
    }
    return {
      isShow: false,
      isDetermineLoading: false,
      form: {
        id: '',
        name: '',
        industryCode: '',
        industryName: '',
        code: '',
        contacts: '',
        tenantUserName: '',
        tel: '',
        initPassword: '',
        email: '',
        gmtStartTime: '',
        gmtEndTime: '',
        fax: '',
        memo: ''
        // addressId: '',
        // countryCode: '',
        // countryName: '',
        // provinceCode: '',
        // provinceName: '',
        // cityCode: '',
        // cityName: '',
        // districtCode: '',
        // districtName: '',
        // address: '',
        // longitude: '',
        // latitude: '',
        // geoHash: ''
      },
      formRules: {
        name: [{ required: true, message: '请输入租户名称', trigger: 'change' }],
        industryCode: [{ required: true, message: '请选择所属行业', trigger: 'change' }],
        code: [{ required: true, message: '请输入租户编号', trigger: 'change' }],
        contacts: [{ required: true, message: '请输入联系人', trigger: 'change' }],
        tenantUserName: [{ required: true, message: '请输入租户账号', trigger: 'change' }],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[0-9]\d{9}$/,
            message: '请输入正确的手机号'
          }
        ],
        initPassword: [{ required: true, message: '请输入租户初始密码', trigger: 'change' }],
        email: [{ validator: validEmail, trigger: 'change' }]
      },
      industrys: [],
      time: []
    }
  },
  watch: {
    isShow (val) {
      if (this.form.id) {
        this.getInfo(this.form.id)
      }
    }
  },
  created () {
    this.getIndustrys()
    this.getProvinceCityCounty()
  },
  methods: {
    onChange (dates, dateStrings) {
      if (dates.length > 0) {
        this.form.gmtStartTime = dates[0].format('YYYY-MM-DD HH:mm:ss')
        this.form.gmtEndTime = dates[1].format('YYYY-MM-DD 23:59:59')
      } else {
        this.form.gmtStartTime = ''
        this.form.gmtEndTime = ''
      }
    },
    async getProvinceCityCounty () {
      const res = await this.$store.dispatch('http', {
        api: 'getProvinceCityCounty'
      })
      if (res) {
        // this.options = res
      }
    },
    // 所属行业列表
    async getIndustrys () {
      const res = await this.$store.dispatch('http', {
        api: 'getDictItems',
        query: {
          code: 'IndustryCategory'
        }
      })
      if (res) {
        const that = this
        res.map(item => {
          that.industrys.push({ code: item.code, name: item.name })
        })
      }
    },
    // 所属行业列表
    async getInfo (id) {
      const res = await this.$store.dispatch('http', {
        api: 'getTenantInfo',
        query: {
          id: id
        }
      })
      if (res) {
        this.form = { ...this.form, ...res }
        this.time[0] = this.form.gmtStartTime
        this.time[1] = this.form.gmtEndTime
      }
    },
    // 设置表单
    setForm (form = {}) {
      this.form = {
        id: '',
        name: '',
        industryCode: '',
        industryName: '',
        code: '',
        contacts: '',
        tenantUserName: '',
        tel: '',
        initPassword: '',
        email: '',
        gmtStartTime: '',
        gmtEndTime: '',
        fax: '',
        memo: ''
        // addressId: '',
        // countryCode: '',
        // countryName: '',
        // provinceCode: '',
        // provinceName: '',
        // cityCode: '',
        // cityName: '',
        // districtCode: '',
        // districtName: '',
        // address: '',
        // longitude: '',
        // latitude: '',
        // geoHash: ''
      }
      this.time = []
    },
    // 关闭
    close () {
      this.setForm()
      this.$refs.form.resetFields()
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.industrys.map(item => {
            if (item.code === this.form.industryCode) {
              this.form.industryName = item.name
            }
          })
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'saveTenant'
          if (params.id) {
            api = 'updateTenant'
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '租户成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>
