<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
       <a-button type="primary" @click="$refs.dialog.setForm();$refs.dialog.isShow = true">新建</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.code" placeholder="编号" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table :data-source="list" :rowKey="record => record.id" :columns="columns" :pagination="pagination">
      <span slot="action1" slot-scope="scope">
        <span>{{scope==='0'?'启用':'禁用'}}</span>
      </span>
       <span slot="action2" slot-scope="scope">
          <div v-for="item in scope.appList" :key="item.name">
            {{ item.name }}
          </div>
      </span>
      <span slot="action" slot-scope="tenant">
        <a @click="$refs.dialog.form = $utils.clone(tenant);$refs.dialog.isShow = true">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
            title="你确定要删除这行内容吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(tenant)"
            @cancel="cancel"
          >
          <a href="#"><a-spin size="small" v-if="tenant.isLoading" />删除</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm
            title= "你确定要禁用吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="statusConfirm(tenant, '9')"
            @cancel="cancel"
            v-if="tenant.status==='0'"
          >
          <a href="#"><a-spin size="small" v-if="tenant.isLoading" />禁用</a>
        </a-popconfirm>
        <a-popconfirm
            title= "你确定要启用吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="statusConfirm(tenant, '0')"
            @cancel="cancel"
            v-if="tenant.status==='9'"
          >
          <a href="#"><a-spin size="small" v-if="tenant.isLoading" />启用</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a @click="$refs.roleDialog.tenant = $utils.clone(tenant);$refs.roleDialog.isShow = true">分配应用角色</a>
      </span>
    </a-table>

    <dialog-save ref="dialog" @save="reloadList"></dialog-save>
    <dialog-role-assign ref="roleDialog" @save="reloadList"></dialog-role-assign>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import DialogSave from './addOrEdit'
import DialogRoleAssign from './saveAppRole'

export default {
  components: {
    Breadcrumb,
    DialogSave,
    DialogRoleAssign
  },
  data () {
    return {
      breadList: [
        {
          name: '租户管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        name: '',
        code: ''
      },
      columns: [
        { title: '租户账号', width: 130, dataIndex: 'tenantUserName', key: 'tenantUserName' },
        { title: '租户名称', width: 100, dataIndex: 'name', key: 'name' },
        { title: '租户编号', width: 100, dataIndex: 'code', key: 'code' },
        { title: '租户状态', width: 70, dataIndex: 'status', key: 'status', scopedSlots: { customRender: 'action1' } },
        { title: '所属行业', width: 100, dataIndex: 'industryName', key: 'industryName' },
        { title: '联系人', width: 100, dataIndex: 'contacts', key: 'contacts' },
        { title: '联系电话', width: 130, dataIndex: 'tel', key: 'tel' },
        { title: '应用', width: 150, key: 'appList', scopedSlots: { customRender: 'action2' } },
        { title: '更新时间', width: 130, dataIndex: 'gmtModified', key: 'gmtModified' },
        { title: '操作', key: 'operation', fixed: 'right', width: 160, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.name = ''
      this.form.code = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryTenants',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除租户
    confirm (tenant) {
      this.del(tenant)
    },
    async del (tenant) {
      tenant.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'deleteTenant',
        query: {
          id: tenant.id
        }
      })
      if (res) {
        tenant.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 租户停用 启用
    statusConfirm (tenant, status) {
      this.updateStatus(tenant, status)
    },
    // 租户停用 启用
    async updateStatus (tenant, status) {
      // tenant.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'updateTenantStatus',
        query: {
          id: tenant.id,
          status: status
        }
      })
      if (res) {
        // tenant.isLoading = false
        this.$store.dispatch('showToast', { message: (status === '0' ? '启用' : '禁用') + '成功~' })
        this.reloadList()
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 6px;
  }
}
</style>
