<template>
  <a-modal :class="$style.container" v-model="isShow" title="分配应用角色：（精耘科技有限公司)" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="roleIds">
        <a-tree
          ref="tree"
          v-model="form.roleIds"
          checkable
          halfChecked
          :checkedKeys="checkedData"
          :tree-data="appRoleList"
          :replaceFields="replaceFields"
          @check="checkRoles"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      appRoleList: [],
      checkedData: [],
      replaceFields: {
        children: 'roleList',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      tenant: {},
      form: {
        roleIds: []
      }
    }
  },
  watch: {
    isShow (val) {
      this.checkedData = []
      this.getManageRoles(this.tenant)
    }
  },
  created () {
    this.getAppRoleList()
  },
  computed: {},
  methods: {
    checkRoles (checkedData, e) {
      let checkedChildNodes = []
      e.checkedNodes.map(item => {
        if (item.componentOptions.children.length <= 0) {
          checkedChildNodes.push(item.key)
        }
      })
      checkedData = checkedChildNodes
      this.form.roleIds = checkedData
    },
    // 获取详情.
    async getManageRoles (tenant) {
      const res = await this.$store.dispatch('http', {
        api: 'getManageRoles',
        query: {
          id: tenant.id
        },
        complete: () => {
        }
      })
      this.form.roleIds = res
    },
    // 获取应用角色列表.
    async getAppRoleList () {
      const res = await this.$store.dispatch('http', {
        api: 'getAppRoleList',
        complete: () => {
        }
      })
      this.appRoleList = res
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        roleIds: []
      }
      this.checkedData = []
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.form.roleIds
          let api = 'saveAppRoles'
          let query = {
            id: this.tenant.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '分配应用角色成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
